<template>
<AuthLayout>   
	<div class="authincation h-100">
			<div class="container h-100">
					<div class="row justify-content-center h-100 align-items-center">
							<div class="col-12 col-lg-10" style="height:100%">
									<div class="authincation-content" style="height:100%">
											<div class="row no-gutters" style="height:100%">
													<div class="col-12">
															<div class="text-center">
																	<a href="jvascript:void(0);">
																			<img class="mt-4" src="https://app.kruti.io/admin_assets/images/Kruti_new.png" width="150px" alt="">
																	</a>
																	<h4 class="text-center my-4 font-weight-bold">Login</h4>
															</div>
													</div>
											</div>
											<div style="position: relative;height:100%">
													<div class="row justify-content-center no-gutters-">
															<div class="col-12 col-md-5 border- border-dark-">
																	<div class="auth-form">
																			<form @submit.prevent="login()" method="post">
																					<input type="hidden" name="_token" value="RQ0zlNJnhl5r8t8HTJvWaVDPrKdLOGJr2YveVfL2">                                        <div class="form-group">
																							<label class="mb-1"><strong>Email</strong></label>
																							<input type="email" v-model="email" name="email" class="form-control rounded-pill border-0 shadow-none p-3" placeholder="hello@example.com" value="" required>
																					</div>
																					<div class="form-group mt-2">
																							<label class="mb-1"><strong>Password</strong></label>
																							<input type="password" v-model="password" name="password" class="form-control rounded-pill border-0 shadow-none p-3" value="" placeholder="Password" required />
																					</div>
																					<div class="form-row d-flex justify-content-between mt-1 mb-2">
																							<div class="form-group">
																							<div class="custom-control custom-checkbox ms-1">
																											<input type="checkbox" class="custom-control-input me-1" name="remember" id="basic_checkbox_1">
																											<label class="custom-control-label" for="basic_checkbox_1">Remember my preference</label>
																									</div>
																							</div>
																							<div class="form-group">
																									<a class="" href="https://app.kruti.io/password/reset">Forgot Password?</a>
																							</div>
																					</div>
																					<div class="text-center mt-4">
																							<button type="submit" class="btn btn-primary w-100 btn-block rounded-pill p-3">Sign In</button>
																					</div>
																			</form>
																			<div class="new-account mt-1 text-center">
																					<p class="mb-0">Don't have an account? <a class="" style="color:rgb(37,168,224);font-weight:bold;" href="https://app.kruti.io/register">Sign Up</a></p>
																			</div>
																	</div>
															</div>
															<!-- <div class="col-12 col-md-1 v-center border- border-dark- position-relative">
																	<div style="height:100%; border:1px solid #E1E1E1;">
																	</div>
																	<div style="position:absolute; left:calc(50% - 20px);">
																			<span class="font-weight-bold rounded-pill bg-white p-2">OR</span>
																	</div>
															</div>
															<div class="col-12 col-md-5 align-self-center v-center- border- border-dark-">
																	<div class="">
																			
																			<div class="google-login-button mb-3 text-center m-4 bg-white rounded-pill p-2 px-1">
																					<a href="https://app.kruti.io/social/redirect/google" class="d-block">
																							<img src="https://app.kruti.io/admin_assets/images/google_icon.png" width="30px" height="30px"/>
																							<span class="font-weight-bold">Login with Google</span>
																					</a>
																			</div>
																	</div>
															</div> -->
													</div>
													
											</div>
									</div>
							</div>
					</div>
			</div>
	</div>
</AuthLayout>
	<!-- <div class="auth-bg">
		<div class="v-center">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col-12 col-md-6 col-lg-4 py-5">
						<div class="card shadow">
							<div class="card-body py-3">
								<div class="text-center my-2">
									<router-link to="/">
										<img src="/assets/img/logo/logo-color.png" height="70px" alt="Respectmart logo" />
									</router-link>
								</div>
								<h1 class="mb-2"><strong> Welcome Back ! </strong></h1>
								<div class="mb-4">Sign in to continue to</div>
								<div class="">
									<div class="form-group mb-4">
										<label for="">Email address</label>
										<input class="form-control" type="email" v-model="email" ref="email" placeholder="Enter Email">
									</div>
									<div class="form-group mb-4">
										<label for="">Password</label>
										<input class="form-control" type="password" v-model="password" ref="password" placeholder="Enter Password">
										<div class="text-end">
											<router-link class="text-secondary" to="/forget-password">Forget Passowrd</router-link>
										</div>

									</div>
									<button class="btn btn-primary w-100" type="submit" v-on:click="login">Login</button>
								</div>
								<div>
									<div class="text-center">Don't have an account? <router-link to="/signup">Sign Up Now</router-link></div>
									<div class="text-center mt-4">© 2024 - Afridext Integrated Services LLC</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->
</template>
<script>
import AuthLayout from '/src/layout/AuthLayout';

import { sendData } from '@/helpers/helpers';

export default{
    name:'LoginView',
    components: {
			AuthLayout
    },
    data(){
			return{
				email:'hassan00942g@gmail.com',
				password:'tayyab123',
			}
    },
		mounted() {
			// this.$notify.show('This is a toast notification!', 'success');
			// this.$notify.show('This is a toast notification s!', 'success');
		},
    methods:{
			async login()
			{
					let output = await sendData(`https://app.kruti.io/api/login`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							email: this.email,
							password: this.password,
						})
					});


					console.log(output);
					if(output != null && output.status == 1){
						localStorage.setItem("token", output.data.token);
						localStorage.setItem("first_name", output.data.first_name);
						localStorage.setItem("last_name", output.data.last_name);
						localStorage.setItem("image", output.data.image);
						localStorage.setItem("provider_id", output.data.provider_id);

						this.$router.push({ name: 'user.dashboard' });
					}

					// .then(response => response.json())
					// .then(data => {
					//   if (data.status == true){
					// 		console.log(data);
					// 		localStorage.setItem("token", data.data.token);
					// 		this.$store.commit('user/setInfo', data.data.user);
					// 		this.$router.push({ name: 'Home' });
					//   }else{
					//     apiErrorHandling(this, data);
					//   }
					// })
					// .catch(error => {
					//   apiErrorHandling(this, error);
					// });
				}
    },
};
</script>