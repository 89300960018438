
<template>
  <router-link :to="{ name: 'user.my-checklist', params: { id: notification.model_id } }" class="dropdown-item" v-if="notification.model_type == 'ListItem'">
    <h6 class="fw-normal mb-0 fw-bold mb-2">{{ notification.title }}</h6>
    <small class="notification_desc text-wrap">{{ notification.body }}</small>
  </router-link>
  <router-link :to="{ name: 'user.my-checklist', params: { id: notification.model_id } }" class="dropdown-item" v-else>
    <h6 class="fw-normal mb-0 fw-bold mb-2">{{ notification.title }}</h6>
    <small class="notification_desc text-wrap">{{ notification.body }}</small>
  </router-link>
  <hr class="dropdown-divider">
</template>
<script>
  export default{
    name: 'NavbarNotificationCardComponent',
    props: {
      notification: {
        type: Object,
        required: true
      },
    }
  }
</script>
