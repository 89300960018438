<template>
<div class="container-fluid position-relative bg-white d-flex p-0">
        <!-- Spinner Start -->
        <!-- <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div> -->
        <!-- Spinner End -->


        <!-- Sidebar Start -->
        <div class="sidebar pe-3 pb-3 bg-white">
            <nav class="navbar navbar-light mx-0 px-0">
                <div class="align-items-center col-12 ms-3 p-2 mb-2">
                    <div class="py-4">
                        <img src="/assets/img/log.png" alt="" style="width: 74px; height: 35px;">
                    </div>
                </div>

                <div class="navbar-nav w-100">
                    <span :to="{ name: 'user.dashboard' }" @click="activateItem('user.dashboard')" class="nav-item nav-link" :class="this.activeRoute == 'user.dashboard' ? 'active': ''">
                        <span><img src="/assets/img/icons/home.png" class="me-3" />Home</span>
                    </span>
                    <span :to="{ name: 'user.my-checklist', params: { id: '0' } }" @click="activateItem('user.my-checklist', { id: '0' })" class="nav-item nav-link" :class="this.activeRoute == 'user.my-checklist' ? 'active': ''">
                        <span><img src="/assets/img/icons/my-checklist.png" class="me-3" />My Checklist</span>
                    </span>                    
                    <span :to="{ name: 'user.assigned-by-me' }" @click="activateItem('user.assigned-by-me')" class="nav-item nav-link" :class="this.activeRoute == 'user.assigned-by-me' ? 'active': ''">
                        <span><img src="/assets/img/icons/assigned-by-me.png" class="me-3" />Assigned By Me</span>
                    </span>
                    <span :to="{ name: 'user.assigned-to-me' }" @click="activateItem('user.assigned-to-me')" class="nav-item nav-link" :class="this.activeRoute == 'user.assigned-to-me' ? 'active': ''">
                        <span><img src="/assets/img/icons/assigned-to-me.png" class="me-3" /> Assigned To Me</span>
                    </span>
                    <span :to="{ name: 'user.calendar' }" @click="activateItem('user.calendar')" class="nav-item nav-link" :class="this.activeRoute == 'user.calendar' ? 'active': ''">
                        <span><img src="/assets/img/icons/my-calendar.png" class="me-3" />My Calendar</span>
                    </span>
                    <a href="#" class="nav-item nav-link">
                        <span><img src="/assets/img/icons/invite.png" class="me-3" />Invite</span>
                    </a>
                    <div class="text-center mt-5 px-4 mb-5" style="padding: 20px, 14px, 20px, 14px !important; gap: 18px;">
                        <h6>Upgrade to Pro <img src="/assets/img/upgrade.png" alt=""></h6>
                        <p>Get 1 month free and unlock all pro features</p>
                        <a href="plan.html" class="py-3 px-5" style="background: #b5d9f6; border: none; border-radius: 50px;">Upgrade</a>
                    </div>
                    <a href="#" class="nav-item nav-link">
                        <span><img src="/assets/img/icons/help-and-information.png" class="me-3" />Help & Information</span>
                    </a>
                    <a href="#" class="nav-item nav-link">
                        <span><img src="/assets/img/icons/logout.png" class="me-3" />Logout</span>
                    </a>
                </div>
            </nav>
        </div>
        <!-- Sidebar End -->


        <!-- Content Start -->
        <div class="content px-2 px-md-4">
            <!-- Navbar Start -->
            <nav class="navbar navbar-expand bg-light- navbar-light- sticky-top- px-4- py-0">
                <div class="nav-item d-none d-lg-inline-flex">
                    <a href="#" class="nav-link d-flex align-items-center">
                        <div class="profile-container">
                            <img class="profile-picture" :src="image" alt="">
                            <div class="status-indicator"></div>
                        </div>
                        <span class="fs-20px ms-4" style="color: #232360;"><b>Welcome back, {{ name }} 👋</b></span>
                    </a>
                </div>
                <a href="#" class="sidebar-toggler flex-shrink-0 d-block  d-md-none d-lg-none">
                    <i class="fa fa-bars"></i>
                </a>
                <!-- <form class="d-none d-md-flex ms-4">
                    <input class="form-control border-0" type="search" placeholder="Search">
                </form> -->
                <div class="navbar-nav align-items-center ms-auto">
                    <div class="nav-item">
                        <span class="nav-link d-flex items-align-items-center">
                            <div>
                                <i><img src="/assets/img/icons/my-calendar.png" alt="" /></i>
                                <span class="d-none d-lg-inline-flex" id="current-date"></span>
                            </div>
                            <span class="my-2 ms-1">{{ todayShort() }}</span>
                        </span>
                    </div>
                    <!-- <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                            <i class="fa fa-envelope me-lg-2"></i>
                            <span class="d-none d-lg-inline-flex">Message</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                            <a href="#" class="dropdown-item">
                                <div class="d-flex align-items-center">
                                    <img class="rounded-circle" src="/assets/img/user.jpg" alt="" style="width: 40px; height: 40px;">
                                    <div class="ms-2">
                                        <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                                        <small>15 minutes ago</small>
                                    </div>
                                </div>
                            </a>
                            <hr class="dropdown-divider">
                            <a href="#" class="dropdown-item">
                                <div class="d-flex align-items-center">
                                    <img class="rounded-circle" src="/assets/img/user.jpg" alt="" style="width: 40px; height: 40px;">
                                    <div class="ms-2">
                                        <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                                        <small>15 minutes ago</small>
                                    </div>
                                </div>
                            </a>
                            <hr class="dropdown-divider">
                            <a href="#" class="dropdown-item">
                                <div class="d-flex align-items-center">
                                    <img class="rounded-circle" src="/assets/img/user.jpg" alt="" style="width: 40px; height: 40px;">
                                    <div class="ms-2">
                                        <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                                        <small>15 minutes ago</small>
                                    </div>
                                </div>
                            </a>
                            <hr class="dropdown-divider">
                            <a href="#" class="dropdown-item text-center">See all message</a>
                        </div>
                    </div> -->
                    <div class="nav-item dropdown">
                        <a href="#" class="nav-link" data-bs-toggle="dropdown">
                            <div class="notification_dot"></div>
                            <i><img src="/assets/img/icons/notification.png" alt=""></i>
                            <!-- <span class="d-none d-lg-inline-flex">Notificatin</span> -->
                        </a>
                        <div class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                            <NavbarNotificationCardComponent v-for="notification in notifications" :key="notification.id" :notification="notification" />
                            <!-- <a href="#" class="dropdown-item">
                                <h6 class="fw-normal mb-0">Profile updated</h6>
                                <small>15 minutes ago</small>
                            </a>
                            <hr class="dropdown-divider">
                            <a href="#" class="dropdown-item">
                                <h6 class="fw-normal mb-0">New user added</h6>
                                <small>15 minutes ago</small>
                            </a>
                            <hr class="dropdown-divider">
                            <a href="#" class="dropdown-item">
                                <h6 class="fw-normal mb-0">Password changed</h6>
                                <small>15 minutes ago</small>
                            </a>
                            <hr class="dropdown-divider"> -->
                            <a href="#" class="dropdown-item text-center" v-if="notifications.length == 0">No Notification</a>
                        </div>
                    </div>
                    <!-- <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                            <img class="rounded-circle me-lg-2" src="/assets/img/user.jpg" alt="" style="width: 40px; height: 40px;">
                            <span class="d-none d-lg-inline-flex">John Doe</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                            <a href="#" class="dropdown-item">My Profile</a>
                            <a href="#" class="dropdown-item">Settings</a>
                            <a href="#" class="dropdown-item">Log Out</a>
                        </div>
                    </div> -->
                </div>
            </nav>
            <!-- Navbar End -->

            <CreateChecklistComponent />

            <slot />  
        </div>
        <!-- Content End -->

        <!-- Back to Top -->
        <!-- <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a> -->
    </div>

    <AssignMemberModal />
    <AttachmentModal />
</template>

<script>
  // import $ from 'jquery';
  // import Chart from 'chart.js';
  // import moment from 'moment';
  // import 'easing';
  // import 'waypoints/lib/jquery.waypoints.min.js';
  // import 'owl.carousel/dist/owl.carousel.min.js';
  // import 'daterangepicker';
  // Import your custom JavaScript
  // import '/src/assets/js/main.js';
  // import '/src/assets/js/bootstrap.bundle.min.js';
  import CreateChecklistComponent from '/src/components/checklist/CreateChecklistComponent';
  import NavbarNotificationCardComponent from '/src/components/navbar/NavbarNotificationCardComponent';
  
  import { sendData } from '@/helpers/helpers';
  import AssignMemberModal from '/src//components/modal/AssignMemberModal.vue';
  import AttachmentModal from '/src//components/modal/AttachmentModal.vue';
//   import { eventBus } from '/src/eventBus.js';


  export default {
    name: 'UserLayout',
    components:{
        CreateChecklistComponent,
        NavbarNotificationCardComponent,
        AssignMemberModal,
        AttachmentModal,
    },
    data(){
        return{
            name: null,
            image: null,
            activeRoute: null,
            notifications: [],
        }
    },
    mounted(){
        const provider_id = localStorage.getItem("provider_id");
        this.name = localStorage.getItem("first_name") + " " + localStorage.getItem("last_name");
        this.image = localStorage.getItem("image");
        this.image = this.imageUrl(this.image, this.name, provider_id);

        this.activeRoute = this.$route.name;

        this.getNotificationsCallApi();
      // JavaScript to insert current date and month
        // document.addEventListener('DOMContentLoaded', (event) => {
        //     console.log(event);
        //     const dateElement = document.getElementById('current-date');
        //     const options = {month: 'long', day: 'numeric' };
        //     const currentDate = new Date().toLocaleDateString(undefined, options);
        //     dateElement.textContent = currentDate;
        // });

        // $(function() {
        //     function formatDateRange(start, end) {
        //         return start.format('MMM DD') + ' – ' + end.format('DD, YYYY');
        //     }

        //     var startDate = moment().startOf('month');
        //     var endDate = moment().endOf('month');

        //     $('.date-picker-custom').daterangepicker({
        //         startDate: startDate,
        //         endDate: endDate,
        //         locale: {
        //             format: 'MMM DD'
        //         }
        //     }, function(start, end) {
        //         $('.date-picker-custom').val(formatDateRange(start, end));
        //     });

        //     $('#prevDayBtn').on('click', function() {
        //         startDate = startDate.subtract(1, 'days');
        //         endDate = endDate.subtract(1, 'days');
        //         $('.date-picker-custom').data('daterangepicker').setStartDate(startDate);
        //         $('.date-picker-custom').data('daterangepicker').setEndDate(endDate);
        //         $('.date-picker-custom').val(formatDateRange(startDate, endDate));
        //     });

        //     $('#nextDayBtn').on('click', function() {
        //         startDate = startDate.add(1, 'days');
        //         endDate = endDate.add(1, 'days');
        //         $('.date-picker-custom').data('daterangepicker').setStartDate(startDate);
        //         $('.date-picker-custom').data('daterangepicker').setEndDate(endDate);
        //         $('.date-picker-custom').val(formatDateRange(startDate, endDate));
        //     });

        //     $('.date-picker-custom').val(formatDateRange(startDate, endDate));
        // });

        // // function togglePopup(element) {
        // //     var popup = element.nextElementSibling;
        // //     if (popup.style.display === "none" || popup.style.display === "") {
        // //         popup.style.display = "flex"; // Ensure flex display
        // //     } else {
        // //         popup.style.display = "none";
        // //     }
        // // }

        // // Close the popup if clicked outside
        // document.addEventListener('click', function(event) {
        //     var isClickInside = event.target.closest('.icon-active');
        //     if (!isClickInside) {
        //         var popups = document.querySelectorAll('.popup');
        //         popups.forEach(function(popup) {
        //             popup.style.display = 'none';
        //         });
        //     }
        // });

    },
    methods: {
        activateItem(r, params = null){
            this.activeRoute = this.$route.name;
            this.$router.push({ name: r, params: params });
        },

        async getNotificationsCallApi(){
            const token = localStorage.getItem("token");
            let output = await sendData(`https://app.kruti.io/api/notifications`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if(output && output.status == 1){
                this.notifications = output.data;
            }else if(output){
                this.$notify.show(output.message, 'error');
            }
        },

        imageUrl(image, name, provider_id){
            if(image == null || image == 'null'){
                return `https://app.kruti.io/name/to/image/${name}`;
            }

            if(provider_id != null && provider_id != ''){
                return image;
            }
        
            return `https://app.kruti.io/${name}`;
        }
    }
  }
</script>

<style scoped lang="scss">
    @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
    // @import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css";
    // @import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
    @import "https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css";
    @import "/src/assets/css/style.css";
    @import "/src/assets/css/bootstrap.min.css";

		// .authincation-content{
		// 	background: transparent !important;
		// 	box-shadow: none !important;
		// }

		// .v-center{display:flex;align-items: center; justify-content: center;}
</style>