<template>
	<UserLayout>
		<div class="container-fluid mb-5">
				<div class="row g-5">
						<div class="col-12">
								<div class="bg-white h-100 p-4 r-10px s-1">
										<div class="clearfix">
												<h6 class="mb-4 float-start text-color-custome">Created Tasks</h6>
												<router-link :to="{ name: 'user.my-checklist', params: { id: '0' } }" class="float-end"><span >View All</span></router-link>
										</div>
										<div class="table-responsive">
												<table class="table">
														<thead>
																<tr class="text-nowrap">
																		<th scope="col" colspan="2" class="border-0">Task</th>
																		<th scope="col" class="border-0">Due Date</th>
																		<th scope="col" class="border-0">Progress Report</th>
																		<th scope="col" class="border-0">Members</th>
																		<th scope="col" class="border-0 text-end">Action</th>
																</tr>
														</thead>
														<tbody>
																<tr class="tabel-row-active td-middle" v-for="(checklist, index) in checklists_created" :key="checklist.id">
																		<th scope="row">{{ index + 1 }}</th>
																		<td class="text-color-custome">
																			<router-link class="text-c1" :to="{ name: 'user.my-checklist', params: { id: checklist.id } }">{{ checklist.title }}</router-link>
																		</td>
																		<td><img src="/assets/img/icons/my-calendar.png" alt=""></td>
																		<td>
																				<div class="d-flex align-items-center">
																						<span class="me-2" style="width:100px" v-if="checklist.status == 'pending'">Pending</span>
																						<span class="me-2" style="width:100px" v-else-if="checklist.status == 'in_progress'">In Progress</span>
																						<span class="me-2" style="width:100px" v-else>Completed</span>
																						<div class="progress" style="flex-grow: 1;">
																								<div v-if="checklist.status == 'pending'" class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
																								<div v-else-if="checklist.status == 'in_progress'" class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
																								<div v-else class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
																						</div>
																				</div>
																		</td>
																		<td>
																				<div class="position-relative d-inline-block">
																						<AssignMemberComponent :assigns="checklist.assign_to" :itemId="checklist.id" />
																						<!-- <img src="/assets/img/member.png" alt="">
																						<span class="centered-plus position-absolute" style="top:50%; left: 50%; transform: translate(-50%, -50%); font-size: 24px;">+</span> -->
																				</div>
																		</td>
																		<td class="text-end ms-5 icon-active dropstart text-center">
																				<i class="fas fa-ellipsis-v cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
																				<div class="dropdown-menu left-popup border-0 rounded-md" @click.stop>
																					<div class="d-flex px-2">
																						<AttachmentComponent style="width:33%" :item="checklist" class="cursor-pointer" />
																						<div class="divider mx-2"></div>
																						<DownloadComponent style="width:33%" :item="checklist" class="cursor-pointer" />
																						<div class="divider mx-2"></div>
																						<DeleteComponent style="width:33%" :item="checklist" class="cursor-pointer" />
																					</div>
																				</div>
																		</td>
																</tr>
																<tr v-if="checklists_created.length == 0">
																	<td colspan="6" class="text-center text-danger" >No record found</td>
																</tr>
																<tr class="d-none">
																		<th scope="row">2</th>
																		<td class="text-color-custome">Birthday paty</td>
																		<td>Today - Mar 20</td>
																		<td>
																				<div class="d-flex align-items-center">
																						<span>In Progress </span>
																						<div class="pg-bar" style="flex-grow: 1;">
																								<div class="progress">
																										<div class="progress-bar progress-bar-striped bg-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
																								</div>
																						</div>
																				</div>
																		
																		</td>
																		<td>
																				<div class="position-relative d-inline-block">
																						<img src="/assets/img/user-1.png" alt="" style="position: absolute; left: 0px;">
																						<img src="/assets/img/user-3.png" alt="" style="border-radius: 50%; position: absolute; left: 20px;">
																						<img src="/assets/img/member.png" alt="" class="text-end" style="margin-left: 2.5rem; position: relative;">
																						<span class="centered-plus position-absolute" style="top: 0%; left: 65%;  font-size: 24px;">+</span>
																				</div>
																		</td>
																		<td class="text-end"><i class="fas fa-ellipsis-v"></i></td>
												
																</tr>
																<tr class="d-none">
																		<th scope="row">3</th>
																		<td class="text-color-custome">Itinerary for Maldives </td>
																		<td>Dec 08 - 20</td>
																		<td>
																				<div class="d-flex align-items-center">
																						<span>In Progress </span>
																						<div class="pg-bar" style="flex-grow: 1;">
																								<div class="progress">
																										<div class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
																								</div>
																						</div>
																				</div>
																		
																		</td>
																		<td>
																				<div class="position-relative d-inline-block">
																						<img src="/assets/img/user-1.png" alt="" style="position: absolute; left: 0px;">
																						<img src="/assets/img/user-3.png" alt="" style="border-radius: 50%; position: absolute; left: 20px;">
																						<img src="/assets/img/member.png" alt="" class="text-end" style="margin-left: 2.5rem; position: relative;">
																						<span class="centered-plus position-absolute" style="top: 0%; left: 65%;  font-size: 24px;">+</span>
																				</div>
																		</td>
																		<td class="text-end"><i class="fas fa-ellipsis-v"></i></td>
												
																</tr>
																
														</tbody>
												</table>
										</div>
								</div>
						</div>
						<div class="col-sm-12 col-xl-6">
								<div class="bg-white h-100 p-4 r-10px s-1">
										<h6 class="mb-4 text-color-custome">Pending</h6>
										<table class="table table-borderless">
												<thead>
														<tr>
																<th scope="col" class="border-0">Task</th>
																<th scope="col" class="border-0">Members</th>
																<th scope="col" class="border-0">Due Date</th>
														</tr>
												</thead>
												<tbody>
														<TableTrComponent v-for="(checklist, index) in checklists_pending" :key="checklist.id" :item="checklist" :index="index" />
														<tr v-if="checklists_pending.length == 0">
															<td colspan="4" class="text-center text-danger" >No record found</td>
														</tr>
														<!-- <tr>
																<td class="text-color-custome">Trip to Goa</td>
																<td class="user-images">
																		<img src="/assets/img/user-1.png" alt="">
																		<img src="/assets/img/user-2.png" alt="">
																		<img src="/assets/img/user-3.png" alt="">
																		<img src="/assets/img/member.png" alt="">
																</td>
																<td>Today - Mar 20</td>
														</tr>
														<tr>
																<td class="text-color-custome">Office Presentation</td>
																<td class="user-images">
																		<img src="/assets/img/user-1.png" alt="">
																		<img src="/assets/img/user-2.png" alt="">
																		<img src="/assets/img/user-3.png" alt="">
																		<img src="/assets/img/member.png" alt="">
																</td>
																<td>Mar 20</td>
														</tr>
														<tr>
																<td class="text-color-custome">Birthday paty</td>
																<td>
																		<img src="/assets/img/user-1.png" alt="">
																		<img src="/assets/img/member.png" alt=""><span>+</span>
																</td>
																<td>Dec 05 - 20</td>
														</tr> -->
												</tbody>
										</table>
								</div>
						</div>
						<div class="col-sm-12 col-xl-6">
								<div class="bg-white h-100 p-4 r-10px s-1">
										<h6 class="mb-4 text-color-custome">In Progress</h6>
										<table class="table table-borderless">
												<thead>
														<tr>
																<th scope="col" class="border-0">Task</th>
																<th scope="col" class="border-0">Members</th>
																<th scope="col" class="border-0">Due Date</th>
														</tr>
												</thead>
												<tbody>
														<TableTrComponent v-for="(checklist, index) in checklists_in_progress" :key="checklist.id" :item="checklist" :index="index" />
														<tr v-if="checklists_in_progress.length == 0">
															<td colspan="4" class="text-center text-danger" >No record found</td>
														</tr>
												</tbody>
										</table>
								</div>
						</div>
						<div class="col-sm-12 col-xl-6">
								<div class="bg-white h-100 p-4 r-10px s-1">
										<h6 class="mb-4 text-color-custome">Completed</h6>
										<table class="table table-borderless">
												<thead>
														<tr>
																<th scope="col" class="border-0">Task</th>
																<th scope="col" class="border-0">Members</th>
																<th scope="col" class="border-0">Due Date</th>
														</tr>
												</thead>
												<tbody>
													<TableTrComponent v-for="(checklist, index) in checklists_completed" :key="checklist.id" :item="checklist" :index="index" />
													<tr v-if="checklists_completed.length == 0">
														<td colspan="4" class="text-center text-danger" >No record found</td>
													</tr>
												</tbody>
										</table>
								</div>
						</div>
						<div class="col-sm-12 col-xl-6">
								<div class="bg-white h-100 p-4 r-10px s-1">
										<h6 class="mb-4 text-color-custome">No Due Date</h6>
										<table class="table table-borderless">
												<thead>
														<tr>
																<th scope="col" class="border-0">Task</th>
																<th scope="col" class="border-0">Members</th>
																<th scope="col" class="border-0">Due Date</th>
														</tr>
												</thead>
												<tbody>
													<TableTrComponent v-for="(checklist, index) in checklists_completed" :key="checklist.id" :item="checklist" :index="index" />
													<tr v-if="checklists_completed.length == 0">
														<td colspan="4" class="text-center text-danger" >No record found</td>
													</tr>
												</tbody>
										</table>
								</div>
						</div>
						<div class="col-12">
								<div class="bg-white h-100 p-4 r-10px s-1">
										<div class="row align-items-center mb-4">
												<div class="col-md-3 col-sm-6">
														<h6 class="mb-4 text-color-custome">Progress Report</h6>
												</div>
												<div class="col-md-8 col-sm-6 text-end py-0 my-0">
														<div class="btn-group me-3">
																<button class="btn btn-white group-btn-index border">Today</button>
														</div>
														<div class="btn-group p-3" role="group" aria-label="Basic example">
																<button type="button" class="btn btn-white border-end-0 border group-btn-index p-2">Year</button>
																<button type="button" class="btn btn-white border-end-0 border group-btn-index p-2">Week</button>
																<button type="button" class="btn btn-white border-end-0 border group-btn-index p-2">Month</button>
																<button type="button" class="btn btn-white border-end-0 border group-btn-index p-2">Day</button>
														</div>
														<button id="prevDayBtn" class="btn btn-white border group-btn-index ms-1 me-2">&lt;</button>
														<input type="text" class="form-control date date-picker-custom" placeholder="Pick the multiple dates">
														<button id="nextDayBtn" class="btn btn-white border group-btn-index ms-2">&gt;</button>
												</div>
										</div>
								
										
										<div class="row">
												<div class="col-md-3 col-sm-6">
														<div>
																<img src="/assets/img/second-log.png" alt="" class="mt-2">
																<button type="button" class="btn btn-outline-primary mt-5 text-color-custome">
																		<img src="/assets/img/icons/download-icon.png" alt=""> Download Report
																</button>   
														</div>
												</div>
												<div class="col-md-3 col-sm-6 text-center">
														<div class="progress-circle">
																<span class="title timer" data-from="0" data-to="100" data-speed="1500">46</span>
																<div class="overlay"></div>
																<div class="left pending"></div>
																<div class="right pending"></div>
														</div>
														<h6>Pending</h6>
												</div>
												<div class="col-md-3 col-sm-6 text-center">
														<div class="progress-circle">
																<span class="title timer" data-from="0" data-to="74" data-speed="1500">74</span>
																<div class="overlay"></div>
																<div class="left in-progress"></div>
																<div class="right in-progress"></div>
														</div>
														<h6>In Progress</h6>
												</div>
												<div class="col-md-3 col-sm-6 text-center">
														<div class="progress-circle">
																<span class="title timer" data-from="0" data-to="70" data-speed="1500">70</span>
																<div class="overlay"></div>
																<div class="left compleated"></div>
																<div class="right compleated"></div>
														</div>
														<h6>Compleated Task</h6>
												</div>
										</div>
								</div>
						</div>
						
				</div>
		</div>
	</UserLayout>
</template>
<script>
import UserLayout from '/src/layout/UserLayout';
import { sendData } from '@/helpers/helpers';
import TableTrComponent from '/src/components/checklist/TableTrComponent';
import DeleteComponent from '/src/components/checklist/DeleteComponent';
import DownloadComponent from '/src/components/checklist/DownloadComponent';
import AttachmentComponent from '/src/components/checklist/AttachmentComponent';
import AssignMemberComponent from '/src/components/checklist/AssignMemberComponent';

export default{
    name: 'DashboardView',
    components: {
			UserLayout,
			TableTrComponent,
			AssignMemberComponent,
			DeleteComponent,
			DownloadComponent,
			AttachmentComponent
    },
    data(){
			return{
				title: null,
				checklists_created: [],
				checklists_pending: [],
				checklists_in_progress: [],
				checklists_completed: [],
				checklists_no_due_date: [],
				progress_report: [],
			}
    },
		mounted() {
			this.getMyChecklist();
		},
    methods:{
			async getMyChecklist(){
				const token = localStorage.getItem("token");
				let output = await sendData(`https://app.kruti.io/api/checklist`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				});

				if(output != null && output.status == 1){
					this.checklists = output.data.created;
					this.checklists_created = output.data.created;
					this.checklists_pending = output.data.pending;
					this.checklists_in_progress = output.data.in_progress;
					this.checklists_completed = output.data.completed;
					this.checklists_no_due_date = output.data.no_due_date;
					this.progress_report = output.data.progress_report;
				}else if(output != null){
					this.$notify.show(output.message, 'error');
				}
			},
			async createChecklistApiCall()
			{
				let output = await sendData(`https://app.kruti.io/api/checklist/create`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						title: this.title,
					})
				});

				if(output.status == 1){
					this.title = null;
					this.$notify.show(output.message, 'success');
				}else{
					this.$notify.show(output.message, 'error');
				}

				console.log(output);
			}
    },
};
</script>