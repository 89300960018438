<template>
	<UserLayout>
    <div class="container-fluid">
        <div class="row g-4">
            <div class="col-12">
                <div class="shadow-sm p-3 rounded bg-white">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-12">
                            <h4>Trip to goa</h4>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12 position-relative">
                            <img src="img/user-1.png" alt="">
                            <img src="img/user-2.png" alt="" style="position: absolute; left: 30px; border-radius: 50%;">
                            <img src="img/user-3.png" alt="" style="position: absolute; left: 50px; border-radius: 50%;">
                            <img src="img/member.png" alt="" style="position: absolute; left: 70px;">
                            <span style="position: absolute; left: 78px; z-index: 999;">+</span>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                            <div class="d-flex align-items-center">
                                <span>Pending </span>
                                <div class="progress ms-3" style="flex-grow: 1;">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12 text-end">
                            <button type="button" class="btn button-custome border-radius-20">+ Add Task</button>
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="col-12">
                <div class="rounded h-100 py-4 px-3 shadow mb-5 bg-white rounded">
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">1. Reserve accommodation near the beach</td>
                                <td class="hover-cell invite icon-active">
                                    <img src="img/icons/invite.png" alt="" class="dropdown-toggle" data-bs-toggle="dropdown"><span class="tooltip">Assign to</span>
                                    <div class="dropdown-menu dropdown-menu-end bg-white border rounded py-3 px-2 m-0" style="width: 240px;">
                                        <h6 ><img src="img/icons/invite.png" alt=""> Transfer to</h6>
                                        <hr>
                                        <div>
                                            <img src="img/user-1.png" alt="">
                                            <img src="img/user-2.png" alt="" style="position: absolute; left: 45px; border-radius: 50%;">
                                            <img src="img/user-3.png" alt="" style="position: absolute; left: 65px; border-radius: 50%;">
                                            <img src="img/user-2.png" alt="" style="position: absolute; left: 85px; border-radius: 50%;">
                                        </div>
                                        <div>
                                            <label for="email">Email</label>
                                            <input type="email" class="form-control" placeholder="Enter Email">
                                        </div>
                                        <div class="text-end mt-3">
                                            <button class="btn bg-white text-dark text-end">Close</button>
                                            <button class="btn button-custome text-white text-end">Send</button>
                                        </div>
                                    </div>  
                                </td>
                                <td class="hover-cell calendar icon-active">
                                    <img src="img/icons/my-calendar.png" class="active-img" alt="" data-bs-toggle="dropdown">
                                    <span class="tooltip">Due Date</span>

                                    <div class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                                        <input type="text" id="calendar-popup" class="calendar-popup">
                                    </div>
                                </td>

                                <td class="hover-cell repeat icon-active">
                                    <img src="img/icons/repeat.png" alt="" class="dropdown-toggle" data-bs-toggle="dropdown"><span class="tooltip">Transfer</span>
                                    <div class="dropdown-menu dropdown-menu-end bg-white border rounded p-4 m-0" style="width: 240px;">
                                        <h6 ><img src="img/icons/repeat.png" alt=""> Transfer to</h6>
                                        <p>1 Trip to goa</p>
                                        <p>2 Birthday paty</p>
                                        <p>3 Office presentation</p>
                                        <p>4 Itinerary for Maldives</p>
                                    </div>
                                </td>
                                <td class="hover-cell delete icon-active"><img src="img/icons/delete.png" alt=""><span class="tooltip">Delete</span></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    2. Pack sunscreen and beachwear
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    3. Plan a visit to Anjuna Flea Market
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    4. Try Goan seafood at a local restaurant
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    5. Explore the churches in Old Goa
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    6. Rent a scooter to explore the town
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    7. Visit Fort Aguada for panoramic views
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    8. Attend a beach party in Baga or Calangute
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    9. Take a boat tour to see dolphins
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                            <tr>
                                <td><img src="img/icons/dot-icon.png" alt=""></td>
                                <td class="user-images">
                                    10. Enjoy a sunset at Vagator Beach
                                </td>
                                <td><img src="img/icons/invite.png" alt=""></td>
                                <td><img src="img/icons/my-calendar.png" alt=""></td>
                                <td><img src="img/icons/repeat.png" alt=""></td>
                                <td><img src="img/icons/delete.png" alt=""></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
	</UserLayout>
</template>
<script>
import UserLayout from '/src/layout/UserLayout';
// import { sendData } from '@/helpers/helpers';

export default{
    name: 'MyChecklistView',
    components: {
			UserLayout
    },
    data(){
			return{
				// email:'hassan00942g@gmail.com',
				// password:'tayyab123',
			}
    },
    methods:{
			async createChecklistApiCall()
			{
				this.$notify.show('Checklist created successfully', 'success');
      }
    },
};
</script>