<template>
  <!-- Modal HTML Structure -->
  <div class="modal fade" :class="{ show: showModal }" :style="{ display: showModal ? 'block' : 'none' }" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Assign CheckList</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <input type="hidden" name="type" value="list">
          <label for="" class="fw-bold mb-1">Email</label>
          <input type="text" v-model="email" name="assign[]" @keydown="checkSpecialKeys" @keyup.enter="saveEmailIntoArray" class="form-control shadow-none" placeholder="Enter Comma Seprated Email" autocomplete="off" />
          <div class="mt-2">
            <span class="bg-light rounded-lg text-dark p-2" v-for="(e,i) in assign" :key="e">
              {{ e }}
              <i class="fa fa-times ms-1 cursor-pointer" @click="removeEmail(i)"></i>
            </span>
          </div>
          <!-- <Select2 v-model="selectedOption" name="assign[]" multiple placeholder="Enter Email" :options="options"></Select2> -->
          <!-- <select v-model="selectedOption" name="assign[]" class="select2" multiple placeholder="Enter Email" :options="options"></Select2> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary rounded-pill" @click="close">Close</button>
          <button type="button" class="btn btn-primary rounded-pill" @click="assignEmails()">Send</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="{ 'show modal-backdrop': showModal }"></div>
</template>

<script>
// import Select2 from 'vue-select2';
// import 'select2/dist/css/select2.min.css';

import { ref, watch, onUnmounted } from 'vue';
import { eventBus } from '/src/eventBus.js';
import NotifyService from '/src/components/notify/NotifyService';
import { sendData } from '@/helpers/helpers';

export default {
  name: 'AssignMemberModal',
  components: {
    // Select2
  },
  setup() {
    const showModal = ref(eventBus.showAssignMemberModal);
    // const selectedOption = ref(null);
    const email = ref(null);
    const assign = ref([]);

    // const options = [
    //   { id: 1, text: 'Option 1' },
    //   { id: 2, text: 'Option 2' },
    //   { id: 3, text: 'Option 3' }
    // ];

    const updateModalVisibility = () => {
      showModal.value = eventBus.showAssignMemberModal;
    };

    watch(() => eventBus.showAssignMemberModal, updateModalVisibility);

    const saveEmailIntoArray = () => {
      const trimmedEmail = (email.value || '').trim();
      if (!trimmedEmail) {
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
      
      if (trimmedEmail !== '' && emailRegex.test(trimmedEmail)) {
        const cleanedEmail = trimmedEmail.replace(/,+$/, ''); // Remove trailing commas
        if (cleanedEmail) {
          assign.value.push(cleanedEmail);
        }
        email.value = ''; // Clear the input after adding
      } else if (trimmedEmail !== '') {
        NotifyService.show('Please enter a valid email address.', 'error');
        email.value = ''; // Clear invalid input
      }
    };
    const checkSpecialKeys = (event) => {
      if (event.key === ',' || event.key === 'Tab') {
        event.preventDefault();
        saveEmailIntoArray();
      }
    };

    const close = () => {
      showModal.value = false;
      eventBus.showAssignMemberModal = false;
    };

    const removeEmail = (index) => {
      if (index >= 0 && index < assign.value.length) {
        assign.value.splice(index, 1);
      }
    };

    const assignEmails = async () => {
      if(assign.value.length == 0){
        NotifyService.show('Please eneter email', 'error');
        return;
      }
      let id = eventBus.selectedCheckListId ;
      const token = localStorage.getItem("token");
      let body =  {
        'type': 'checklist',
        'email': assign.value,
      };
      let output = await sendData(`https://app.kruti.io/api/checklist-or-task/add-user/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    });

    if(output && output.status == 1){
        this.notifications = output.data;
        close();
    }else if(output){
        NotifyService.show(output.message, 'error');
    }
    };

    onUnmounted(() => {
      eventBus.showAssignMemberModal = false;
    });

    return {
      showModal,
      close,
      email,
      assign,
      saveEmailIntoArray,
      checkSpecialKeys,
      removeEmail,
      assignEmails
      // selectedOption,
      // options
    };
  },
}
</script>

<style>
.modal.show {
  display: block;
}
</style>
