<template>
	<UserLayout>
    <div class="container-fluid">
        <div class="row g-4">
            <div class="col-12">
                <div class="bg-white rounded h-100 p-4 shadow p-3 mb-5 bg-body rounded">
                    <h6 class="mb-4 text-color-custome">Assigned by me</h6>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" class="border-0">Task</th>
                                    <th scope="col" class="border-0">Assigned To</th>
                                    <th scope="col" class="border-0">Due Date</th>
                                    <th scope="col" class="border-0">Progress Report</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="tabel-row-active">
                                    <th scope="row" class="text-color-custome">Trip to goa</th>
                                    <td class="text-color-custome">Prakash Mishra</td>
                                    <td> <img src="img/icons/my-calendar.png" alt=""></td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <span>Pending </span>
                                            <div class="progress ms-3" style="flex-grow: 1;">
                                                <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-color-custome">Birthday paty</th>
                                    <td class="text-color-custome">Priyanka shah</td>
                                    <td> Today - Mar 20</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <span>In Progress </span>
                                            <div class="pg-bar" style="flex-grow: 1;">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-color-custome">Office presentation </th>
                                    <td class="text-color-custome">Yagnesh Bansal</td>
                                    <td> May -  12</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <span>Pending </span>
                                            <div class="progress ms-3" style="flex-grow: 1;">
                                                <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-color-custome">Itinerary for Maldives </th>
                                    <td class="text-color-custome">Anjali Naira</td>
                                    <td> Dec 08 - 20</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <span>Done </span>
                                            <!-- <div class="pg-bar mb-3"> -->
                                                <div class="progress ms-3" style="flex-grow: 1;">
                                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                                </div>
                                            <!-- </div> -->
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-color-custome">Trip to goa</th>
                                    <td class="text-color-custome">Prakash Mishra</td>
                                    <td> Today - Mar 20</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <span>In Progress </span>
                                            <div class="pg-bar" style="flex-grow: 1;">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-color-custome">Office presentation</th>
                                    <td class="text-color-custome">Rita Vakheria</td>
                                    <td> May -  12</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <span>Pending </span>
                                            <div class="progress ms-3" style="flex-grow: 1;">
                                                <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-color-custome">Itinerary for Manali </th>
                                    <td class="text-color-custome">Akash jain</td>
                                    <td>Dec 08 - 20</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <span>Done </span>
                                            <!-- <div class="pg-bar mb-3"> -->
                                                <div class="progress ms-3" style="flex-grow: 1;">
                                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                                </div>
                                            <!-- </div> -->
                                        </div>
                                    </td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
	</UserLayout>
</template>
<script>
import UserLayout from '/src/layout/UserLayout';
// import { sendData } from '@/helpers/helpers';

export default{
    name: 'AssignedByMeView',
    components: {
			UserLayout
    },
    data(){
			return{
				// email:'hassan00942g@gmail.com',
				// password:'tayyab123',
			}
    },
    methods:{
			async createChecklistApiCall()
			{
				this.$notify.show('Checklist created successfully', 'success');
      }
    },
};
</script>