import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '/src/views/Auth/LoginView.vue'
import DashboardView from '/src/views/DashboardView.vue'
import MyChecklistView from '/src/views/Checklist/MyChecklistView.vue'
import AssignedByMeView from '/src/views/Checklist/AssignedByMeView.vue'
import AssignedToMeView from '/src/views/Checklist/AssignedToMeView.vue'
import CalendarView from '/src/views/Checklist/CalendarView.vue'

const router = createRouter({
  history: createWebHistory(), //import.meta.env.BASE_URL
  routes: [
    {
      path: '/',
      name: 'auth.login',
      component: LoginView
    },
    {
      path: '/dashboard',
      name: 'user.dashboard',
      component: DashboardView,
      meta: { requiresAuth: true }
    },
    {
      path: '/my-checklist/:id',
      name: 'user.my-checklist',
      component: MyChecklistView,
      meta: { requiresAuth: true }
    },
    {
      path: '/assigned-by-me',
      name: 'user.assigned-by-me',
      component: AssignedByMeView,
      meta: { requiresAuth: true }
    },
    {
      path: '/assigned-to-me',
      name: 'user.assigned-to-me',
      component: AssignedToMeView,
      meta: { requiresAuth: true }
    },
    {
      path: '/calendar',
      name: 'user.calendar',
      component: CalendarView,
      meta: { requiresAuth: true }
    },
  ],
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       el: to.hash,
  //       behavior: 'smooth',
  //     };
  //   }
  //   return { top: 0 };
  // },
});

// router.beforeEach((to, from, next) => {
//   // Assuming each component has a metaInfo method to get dynamic title
//   if (to.matched.some(record => record.meta.title)) {
//     const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

//     if (nearestWithTitle) {
//       const instance = new Vue(nearestWithTitle.component);
//       document.title = typeof instance.metaInfo === 'function'
//         ? instance.metaInfo().title
//         : 'Default Title';
//     }
//   }

//   next();
// });

// router.beforeEach((to, from, next) => {
//   console.log(from);
// 	const token = localStorage.getItem('token');
// 	const loggedIn = !!token;
	
// 	if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
// 		next('/');
// 	}else if(loggedIn && to.path === '/'){
// 		next('/');
// 	} else {
// 		next();
// 	}
// });
export default router
