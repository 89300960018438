<template>
  <div class="toast-container">
    <div class="position-fixed p-3" style="z-index: 11; bottom: 0px; right:0px !important">
      <div
          class="toast show cursor-pointer"
          :class="type"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          @click="removeNotification()"
        >
        <div class="toast-body" v-html="message"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    type: {
      type: String,
      default: 'info'
    }
  },
  watch: {
    message(newValue, oldValue) {
      console.log(newValue + oldValue);
    }
  },
  data() {
    return {
      notifications: []
    };
  },
  methods: {
    // addNotification(message, type) {
    //   this.notifications.push({ msg: message, type });
    //   setTimeout(() => {
    //     this.notifications.shift(); // Remove the oldest notification
    //   }, 3000); // Adjust duration as needed
    // },
    removeNotification() {
        // index
        //  this.notifications.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.success {
  background-color: #67E73D;
  color: #000000;
}

.error {
  background-color: #EA0606;
  color: #FFFFFF;
}

.info {
  background-color: #3D92E7;
  color: #000000;
}

.warning {
  background-color: #FFAA00;
  color: #000000;
}

.toast{border-radius:20px;box-shadow: none !important;}
</style>
