
<template>
  <span @click="download(item)">
    <img src="/assets/img/icons/download-icon.png" alt="Download" class="ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Download">
  </span>
</template>
<script>
  // import { sendData } from '@/helpers/helpers';
  export default{
    name:'DownloadComponent',
    components: {
    },
    props: {
      item: {
        type: Object,
        required: true
      },
    },
    methods:{
      async download(item){
        console.log(item.id);
        const link = document.createElement('a');
        link.href = `https://app.kruti.io/checklist/download/${item.id}`;
        link.target = '_blank';
        link.click();
        this.$notify.show('Downloading Checklist', 'success');
      },
    }
  }
</script>
