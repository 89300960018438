// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
// import 'toastr/build/toastr.min.css'
// import store from './store/index'

// import '/src/assets/js/vendor.min.js';
// import '/src/assets/js/theme.min.js';
import mixin from './helpers/mixin.js'

import NotifyService from './components/notify/NotifyService';



const app = createApp(App);

app.use(router);
app.mixin(mixin);
app.config.globalProperties.$notify = NotifyService;
console.log(app.config.globalProperties.$notify);

app.mount('#app');