
<template>
  <div v-for="assign in assigns" :key="assign.id" class="user-images float-start">
    <img :src="imageUrl(assign.user)" alt="" width="28px" />
  </div>
  <div class="position-relative cursor-pointer float-start" @click="openModal(itemId)">
    <img src="/assets/img/member.png" alt="" >
    <span class="centered-plus position-absolute" style="top:50%; left: 50%; transform: translate(-50%, -50%); font-size: 24px;">+</span>
  </div>
</template>
<script>
  import { eventBus } from '/src/eventBus.js';

  export default{
    name: 'AssignMemberComponent',
    props: {
      assigns: {
        type: Object,
        required: true
      },
      itemId: {
        type: Number,
        required: true
      },
    },
    methods: {
      openModal(itemId) {
        console.log(itemId);
        eventBus.selectedCheckListId = itemId;
        eventBus.showAssignMemberModal = true;
      },

      imageUrl(user){
        if(user.image == null){
          return `https://app.kruti.io/name/to/image/${user.first_name}`;
        }

        if(user.provider_id != null && user.provider_id != ''){
          return user.image;
        }
       
        return `https://app.kruti.io/${user.first_name}`;

      }
    }
  }
</script>
