export default {
  methods: {
    to(link, {receiver = null}) {

      if(receiver != null){
        this.$store.commit('hold/setReceiver', receiver);
        this.$router.push(link);
        return;
      }

      this.$router.push(link);
    },


    todayShort(){
      const date = new Date();

      return this.datedM(date);
    },

    today(){
      const date = new Date();

      return this.dateMdY(date) + " " + this.getTime(date);
    },

    getTime(date){
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'

      
      minutes = minutes < 10 ? '0' + minutes : minutes;
      hours = hours < 10 ? "0"+hours : hours;

      // Combine into a formatted string
      const formattedTime = hours + ':' + minutes + ' ' + ampm;
      return formattedTime;
    },

    formatDate(d){
      const date = new Date(d);
      var month = date.getMonth() + 1;
      var dd = date.getDate();
      var year = date.getFullYear();

      if(month < 10)
       month = `0${month}`;
       
      if(dd < 10)
        dd = `0${dd}`;

      const formattedDate = `${dd}-${month}-${year}`;
      return formattedDate;
    },

    datedM(d){
      
      const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
      ];

      const date = new Date(d);
      const month = months[date.getMonth()];
      var dd = date.getDate();

       
      if(dd < 10)
        dd = `0${dd}`;


      const formattedDate = `${dd} ${month}`;
      return formattedDate;
    },

    dateMdY(d){
      
      const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
      ];

      const date = new Date(d);
      const month = months[date.getMonth()];
      var dd = date.getDate();
      const year = date.getFullYear();

       
      if(dd < 10)
        dd = `0${dd}`;


      const formattedDate = `${month} ${dd}, ${year}`;
      return formattedDate;
    },
  }
};
