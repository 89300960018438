import { createApp, h } from 'vue';
import NotifyComponent from '/src/components/notify/NotifyComponent.vue';

const NotifyService = {
  show(message, type = 'info', duration = 3000) {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const app = createApp({

      render() {
        return h(NotifyComponent, { message, type });
      }
    });

    app.mount(container);

    
    setTimeout(() => {
      app.unmount();
      document.body.removeChild(container);
    }, duration);
  }
};

export default NotifyService;
