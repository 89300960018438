<template>
  <!-- Modal HTML Structure -->
  <div class="modal fade" :class="{ show: showModal }" :style="{ display: showModal ? 'block' : 'none' }" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Attachment Modal</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <input type="hidden" name="type" value="list">
          <label for="" class="fw-bold mb-1">Attachemt Type</label>
          <select type="text" v-model="type" class="form-control shadow-none">
            <option value="">-Choose-</option>
            <option value="location">Location</option>
            <option value="image">Image</option>
            <option value="document">Document</option>
            <option value="url">Url</option>
            <option value="contact">Contact</option>
          </select>

          <div :class="type == 'location' ? 'mt-3' : 'd-none'" >
            <label for="" class="fw-bold mb-1">Enter Place</label>
            <input type="text" v-model="location"  placeholder="Search address" class="form-control shadow-none" />
          </div>
          
          <div :class="type == 'image' ? 'mt-3' : 'd-none'" >
            <label for="" class="fw-bold mb-1">Image</label>
            <input type="file" class="form-control shadow-none" />
          </div>
          
          <div :class="type == 'document' ? 'mt-3' : 'd-none'" >
            <label for="" class="fw-bold mb-1">Document</label>
            <input type="file" class="form-control shadow-none" />
          </div>

          <div :class="type == 'url' ? 'mt-3' : 'd-none'" >
            <label for="" class="fw-bold mb-1">Url</label>
            <input type="text" v-model="location" placeholder="https://" class="form-control shadow-none" />
          </div>

          <div :class="type == 'contact' ? 'mt-3' : 'd-none'" >
            <div>
              <label for="" class="fw-bold mb-1">Name</label>
              <input type="text" v-model="location" placeholder="Search address" class="form-control shadow-none" />
            </div>
            <div class="mt-3">
              <label for="" class="fw-bold mb-1">Phone</label>
              <input type="text" v-model="location" placeholder="Search address" class="form-control shadow-none" />
            </div>
            <div class="mt-3">
              <label for="" class="fw-bold mb-1">Email</label>
              <input type="text" v-model="location" placeholder="Search address" class="form-control shadow-none" />
            </div>
          </div>

          <div class="mt-2">
            <span class="bg-light rounded-lg text-dark p-2" v-for="(e,i) in assign" :key="e">
              {{ e }}
              <i class="fa fa-times ms-1 cursor-pointer" @click="removeEmail(i)"></i>
            </span>
          </div>
          <!-- <Select2 v-model="selectedOption" name="assign[]" multiple placeholder="Enter Email" :options="options"></Select2> -->
          <!-- <select v-model="selectedOption" name="assign[]" class="select2" multiple placeholder="Enter Email" :options="options"></Select2> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary rounded-pill" @click="close">Close</button>
          <button type="button" class="btn btn-primary rounded-pill" @click="close">Send</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="{ 'show modal-backdrop': showModal }"></div>
</template>

<script>
// import Select2 from 'vue-select2';
// import 'select2/dist/css/select2.min.css';

import { ref, watch, onUnmounted } from 'vue';
import { eventBus } from '/src/eventBus.js';
// import NotifyService from '/src/components/notify/NotifyService';
// import { sendData } from '@/helpers/helpers';

export default {
  name: 'AttachmentModal',
  components: {
    // Select2
  },
  setup() {
    const showModal = ref(eventBus.showAttachmentModal);
    // const selectedOption = ref(null);
    const type = ref(null);
    const location = ref(null);
    const url = ref(null);
    const image = ref(null);
    const document = ref(null);
    const contact_name = ref(null);
    const contact_phone = ref(null);
    const contact_email = ref(null);

    const updateModalVisibility = () => {
      showModal.value = eventBus.showAttachmentModal;
    };

    watch(() => eventBus.showAttachmentModal, updateModalVisibility);

    // const saveEmailIntoArray = () => {
    //   const trimmedEmail = (email.value || '').trim();
    //   if (!trimmedEmail) {
    //     return;
    //   }

    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
      
    //   if (trimmedEmail !== '' && emailRegex.test(trimmedEmail)) {
    //     const cleanedEmail = trimmedEmail.replace(/,+$/, ''); // Remove trailing commas
    //     if (cleanedEmail) {
    //       assign.value.push(cleanedEmail);
    //     }
    //     email.value = ''; // Clear the input after adding
    //   } else if (trimmedEmail !== '') {
    //     NotifyService.show('Please enter a valid email address.', 'error');
    //     email.value = ''; // Clear invalid input
    //   }
    // };
    // const checkSpecialKeys = (event) => {
    //   if (event.key === ',' || event.key === 'Tab') {
    //     event.preventDefault();
    //     saveEmailIntoArray();
    //   }
    // };

    const close = () => {
      showModal.value = false;
      eventBus.showAttachmentModal = false;
    };

    // const removeEmail = (index) => {
    //   if (index >= 0 && index < assign.value.length) {
    //     assign.value.splice(index, 1);
    //   }
    // };

    // const assignEmails = async () => {
    //   if(assign.value.length == 0){
    //     NotifyService.show('Please eneter email', 'error');
    //     return;
    //   }
    //   let id = eventBus.selectedCheckListId ;
    //   const token = localStorage.getItem("token");
    //   let body =  {
    //     'type': 'checklist',
    //     'email': assign.value,
    //   };
    //   let output = await sendData(`https://app.kruti.io/api/checklist-or-task/add-user/${id}`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(body)
    // });

    // if(output && output.status == 1){
    //     this.notifications = output.data;
    //     close();
    // }else if(output){
    //     NotifyService.show(output.message, 'error');
    // }
    // };

    onUnmounted(() => {
      eventBus.showAttachmentModal = false;
    });

    return {
      showModal,
      type,
      location,
      url,
      image,
      document,
      contact_name,
      contact_phone,
      contact_email,
      close,
      // saveEmailIntoArray,
      // checkSpecialKeys,
      // removeEmail,
      // assignEmails
      // selectedOption,
      // options
    };
  },
}
</script>

<style>
.modal.show {
  display: block;
}
</style>
