<template>
  <div>
    <slot />  
  </div>
</template>

<script>
  export default {
    name: 'AuthLayout',
    components:{
        
    },
    mounted(){
      
    },
  }
</script>

<style scoped lang="scss">
    // @import "https://app.kruti.io/admin_assets/css/style.css";

		.authincation-content{
			background: transparent !important;
			box-shadow: none !important;
		}

		.v-center{display:flex;align-items: center; justify-content: center;}
</style>