// import toastr from 'toastr';
import NotifyService from '/src/components/notify/NotifyService';



export function apiErrorHandling(context, data) {
  console.log("apiErrorHandling");


  if(data.message == "Validation errors"){
    Object.keys(data.data).forEach(field => {
      // context.notify.add('success', 'This is a success message!');
      // toastr.error(data.data[field][0]);
      NotifyService.show(data.data[field][0], 'error');
    });
  }else{
    NotifyService.show(data.message, 'error');
    // toastr.error(data.message);
  }

  if(data.data == undefined){
    return;
  }

  // clear old errors
  document.querySelectorAll('.error').forEach(errorDiv => {
    errorDiv.remove();
  });

  // append new error
  Object.keys(data.data).forEach(field => {
    const input = context.$refs[`${field}`];

    const errorMessages = data.data[field];
    errorMessages.forEach(errorMessage => {
      const errorDiv = document.createElement('div');
      errorDiv.classList.add('text-danger');
      errorDiv.classList.add('error');
      errorDiv.textContent = errorMessage;
      if(input != null){
        input.parentNode.insertBefore(errorDiv, input.nextSibling);
      }
    });
  });
}

export async function fetchData(url, {method = 'GET', headers = {}} = {}){
  let a = null;

  const token = localStorage.getItem("token");
  headers['Content-Type'] = 'application/json';
  if(token){
    headers['Authorization'] = `Bearer ${token}`;
  }

  console.log(headers);

  await fetch(url, {
    method: method,
    headers: headers,
  })
  .then(response => response.json())
  .then(data => {
    console.error('data:', data);
    if (data && data.status == true){
      a = data.data;
    }else{
      console.log(data);
      apiErrorHandling(this, data);
    }
  })
  .catch(error => {
    console.log(error);
    apiErrorHandling(this, error);
    return null;
  })
  .finally(() => {
    console.log(a);
    return a;
  });

  return a;
}

export async function sendData(url, {method = 'GET', headers= null, body = null} = {}){
  let a = null;

  // const token = localStorage.getItem("token");
  // const headers =  {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${token}`,
  // };
  // body = JSON.stringify(body);

  if(headers){
    headers['Access-Control-Allow-Origin'] = '*';
  }else{
      headers =  {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
      };
  }
  headers['Accept'] = 'application/json';

  // console.log(headers);
  // console.log(body);

  await fetch(url, {
    method: method,
    // mode: 'no-cors',
    headers: headers,
    body: body,
  })
  .then(response => response.json())
  .then(data => {
    console.error('data:', data);
    if (data.status == true){
      // toastr.success(data.message);
      if(data.message){
        NotifyService.show(data.message, 'success');
      }

      a = data;
    }else{
      apiErrorHandling(this, data);
    }
  })
  .catch(error => {
    console.log(error);
    apiErrorHandling(this, error);
    return null;
  })
  .finally(() => {
    console.log(a);
    return a;
  });

  return a;
}
