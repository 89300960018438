<template>
	<UserLayout>
            <!-- Sale & Revenue Start -->
            <div class="container-fluid">
                <div class="row g-4">
                    <div class="col-12">
                        <div class="bg-white rounded h-100 p-4 shadow p-3 mb-5 bg-body rounded">
                            <h6 class="mb-4 text-color-custome">Assigned to me</h6>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colspan="2" class="border-0">Task</th>
                                            <th scope="col" class="border-0">Assigned To</th>
                                            <th scope="col" class="border-0">Due Date</th>
                                            <th scope="col" class="border-0">Progress Report</th>
                                            <th scope="col" class="border-0">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="tabel-row-active">
                                            <td><input type="checkbox" name="" id=""></td>
                                            <th scope="row" class="text-color-custome">Trip to goa</th>
                                            <td class="text-color-custome">Prakash Mishra</td>
                                            <td> <img src="img/icons/my-calendar.png" alt=""></td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span>Pending </span>
                                                    <div class="progress ms-3" style="flex-grow: 1;">
                                                        <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center icon-active">
                                                <i class="fas fa-ellipsis-v" data-bs-toggle="dropdown"></i>
                                                <div class="dropdown-menu dropdown-menu-end bg-white border rounded p-2">
                                                    <img src="img/icons/invite.png" alt="" class="m-2 me-3 dropdown-toggle invite" data-bs-toggle="dropdown" data-bs-toggle-="tooltip" data-bs-placement="top" title="Assign to">
                                                    <div class="dropdown-menu dropdown-menu-end bg-white border rounded py-3 px-2 m-0" style="width: 240px;">
                                                        <h6 ><img src="img/icons/invite.png" alt=""> Transfer to</h6>
                                                        <hr>
                                                        <div>
                                                            <img src="img/user-1.png" alt="">
                                                            <img src="img/user-2.png" alt="" style="position: absolute; left: 45px; border-radius: 50%;">
                                                            <img src="img/user-3.png" alt="" style="position: absolute; left: 65px; border-radius: 50%;">
                                                            <img src="img/user-2.png" alt="" style="position: absolute; left: 85px; border-radius: 50%;">
                                                        </div>
                                                        <div>
                                                            <label for="email">Email</label>
                                                            <input type="email" class="form-control" placeholder="Enter Email">
                                                        </div>
                                                        <div class="text-end mt-3">
                                                            <button class="btn bg-white text-dark text-end">Close</button>
                                                            <button class="btn button-custome text-white text-end">Send</button>
                                                        </div>
                                                    </div> 
                                                    <img src="img/icons/download-icon.png" alt="" class="m-2 me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Download">
                                                    <img src="img/icons/attached.png" alt="" class="m-2 ms-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Attachment">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" name="" id=""></td>
                                            <th scope="row" class="text-color-custome">Birthday paty</th>
                                            <td class="text-color-custome">Priyanka shah</td>
                                            <td> Today - Mar 20</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span>In Progress </span>
                                                    <div class="pg-bar" style="flex-grow: 1;">
                                                        <div class="progress">
                                                            <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center"><i class="fas fa-ellipsis-v"></i></td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" name="" id=""></td>
                                            <th scope="row" class="text-color-custome">Office presentation </th>
                                            <td class="text-color-custome">Yagnesh Bansal</td>
                                            <td> May -  12</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span>Pending </span>
                                                    <div class="progress ms-3" style="flex-grow: 1;">
                                                        <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center"><i class="fas fa-ellipsis-v"></i></td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" name="" id=""></td>
                                            <th scope="row" class="text-color-custome">Itinerary for Maldives </th>
                                            <td class="text-color-custome">Anjali Naira</td>
                                            <td> Dec 08 - 20</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span>Done </span>
                                                    <!-- <div class="pg-bar mb-3"> -->
                                                        <div class="progress ms-3" style="flex-grow: 1;">
                                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                                        </div>
                                                    <!-- </div> -->
                                                </div>
                                            </td>
                                            <td class="text-center"><i class="fas fa-ellipsis-v"></i></td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" name="" id=""></td>
                                            <th scope="row" class="text-color-custome">Trip to goa</th>
                                            <td class="text-color-custome">Prakash Mishra</td>
                                            <td> Today - Mar 20</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span>In Progress </span>
                                                    <div class="pg-bar" style="flex-grow: 1;">
                                                        <div class="progress">
                                                            <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center"><i class="fas fa-ellipsis-v"></i></td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" name="" id=""></td>
                                            <th scope="row" class="text-color-custome">Office presentation</th>
                                            <td class="text-color-custome">Rita Vakheria</td>
                                            <td> May -  12</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span>Pending </span>
                                                    <div class="progress ms-3" style="flex-grow: 1;">
                                                        <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center"><i class="fas fa-ellipsis-v"></i></td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" style="border: none; background: #b5d9f6;" name="" id=""></td>
                                            <th scope="row" class="text-color-custome">Itinerary for Manali </th>
                                            <td class="text-color-custome">Akash jain</td>
                                            <td>Dec 08 - 20</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span>Done </span>
                                                    <!-- <div class="pg-bar mb-3"> -->
                                                        <div class="progress ms-3" style="flex-grow: 1;">
                                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 20%;"></div>
                                                        </div>
                                                    <!-- </div> -->
                                                </div>
                                            </td>
                                            <td class="text-center"><i class="fas fa-ellipsis-v"></i></td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
	</UserLayout>
</template>
<script>
import UserLayout from '/src/layout/UserLayout';
// import { sendData } from '@/helpers/helpers';

export default{
    name: 'AssignedToMeView',
    components: {
			UserLayout
    },
    data(){
			return{
				// email:'hassan00942g@gmail.com',
				// password:'tayyab123',
			}
    },
    methods:{
			async createChecklistApiCall()
			{
				this.$notify.show('Checklist created successfully', 'success');
      }
    },
};
</script>