<template>
	<UserLayout>
    <!-- Sale & Revenue Start -->
    <div class="container-fluid pt-4">
        <div class="row g-4">
            <div class="col-12">
                <div id='calendar'></div>
            </div>
        </div>
    </div>
    <!-- Sale & Revenue End -->
	</UserLayout>
</template>
<script>
import UserLayout from '/src/layout/UserLayout';
// import { sendData } from '@/helpers/helpers';

export default{
    name: 'CalendarView',
    components: {
			UserLayout
    },
    data(){
			return{
				// email:'hassan00942g@gmail.com',
				// password:'tayyab123',
			}
    },
    async mounted() {
      // await this.loadScript('https://cdn.jsdelivr.net/npm/@fullcalendar/core@4.2.0/main.min.js');
      // await this.loadScript('https://cdn.jsdelivr.net/npm/@fullcalendar/daygrid@4.2.0/main.min.js');
      // await this.loadScript('https://cdn.jsdelivr.net/npm/@fullcalendar/timegrid@4.2.0/main.min.js');
      // await this.loadScript('https://cdn.jsdelivr.net/npm/@fullcalendar/interaction@4.2.0/main.min.js');

      // // Initialize FullCalendar
      // const calendarEl = this.$refs.calendarContainer;

      // const calendar = new FullCalendar.Calendar(calendarEl, {
      //   plugins: [FullCalendar.dayGridPlugin, FullCalendar.timeGridPlugin, FullCalendar.interactionPlugin],
      //   initialView: 'dayGridMonth',
      //   events: [
      //     { title: 'Event 1', date: '2024-07-25' },
      //     { title: 'Event 2', date: '2024-07-26' }
      //   ]
      // });

      // calendar.render();
    },
    methods:{
      // loadScript(src) {
      //   return new Promise((resolve, reject) => {
      //     const script = document.createElement('script');
      //     script.src = src;
      //     script.onload = () => resolve();
      //     script.onerror = () => reject(new Error(`Script load error for ${src}`));
      //     document.head.appendChild(script);
      //   });
      // },
			async createChecklistApiCall()
			{
				this.$notify.show('Checklist created successfully', 'success');
      }
    },
};
</script>