
<template>
  <span @click="del(item)">
    <img src="/assets/img/icons/delete.png" alt="Delete" class="ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
  </span>
</template>
<script>
  import { sendData } from '@/helpers/helpers';
  export default{
    name:'DeleteComponent',
    components: {
    },
    props: {
      item: {
        type: Object,
        required: true
      },
    },
    methods:{
      async del(item){
        console.log(item.id);
        const token = localStorage.getItem("token");
				let output = await sendData(`https://app.kruti.io/api/checklist/delete/${item.id}`, {
					method: 'Delete',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				});

				if(output != null && output.status == 1){
					this.$notify.show(output.message, 'success');
          location.reload();
				}else if(output != null){
					this.$notify.show(output.message, 'error');
				}
      },
    }
  }
</script>
