
<template>
  <span @click="openModal(item.id)">
    <img src="/assets/img/icons/attached.png" alt="Attachment" class="ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Attachment">
  </span>
</template>
<script>
  // import { sendData } from '@/helpers/helpers';
  import { eventBus } from '/src/eventBus.js';
  export default{
    name:'AttachmentComponent',
    components: {
    },
    props: {
      item: {
        type: Object,
        required: true
      },
    },
    methods:{
      openModal(itemId) {
        console.log(itemId);
        eventBus.selectedCheckListId = itemId;
        eventBus.showAttachmentModal = true;
      },
      // async del(item){
      //   console.log(item);
      // },
    }
  }
</script>
