
<template>
  <tr>
    <td class="text-color-custome">
      <router-link class="text-c1" :to="{ name: 'user.my-checklist', params: { id: item.id } }">{{ item.title }}</router-link>
    </td>
    <td class="user-images">
      <AssignMemberComponent :assigns="item.assign_to" :itemId="item.id" />
      <!-- <div class="position-relative">
        <img src="/assets/img/member.png" alt="">
        <span class="centered-plus position-absolute" style="top:50%; left: 50%; transform: translate(-50%, -50%); font-size: 24px;">+</span>
      </div> -->
    </td>
    <td>
      <span v-if="item.completion_date">{{ item.completion_date }}</span>
      <span v-else>
        <img src="/assets/img/icons/my-calendar.png" alt="">
      </span>
    </td>
  </tr>
</template>
<script>
  import AssignMemberComponent from '/src/components/checklist/AssignMemberComponent';

  export default{
    name:'TableTrComponent',
    components: {
			AssignMemberComponent
    },
    props: {
      item: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
    }
  }
</script>
