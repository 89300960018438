
<template>
  <div class="container-fluid mt-4 mb-4">
    <div class="row">
        <div class="col-sm-12 col-xl-12">
          <form @submit.prevent="createChecklistApiCall()">
            <div class="input-group rounded">
                <span class="input-group-text" id="search-icon" style="border-radius: 20px 0 0 20px; background: white; border: none;"><i class="fas fa-search"></i></span>
                <input type="text" v-model="title" class="form-control shadow-none" placeholder="Enter title to create strategic task list..." aria-label="Search" aria-describedby="search-icon" style="border: none;">
                <button class="btn py-3 px-5 shadow-none" style="background: #e4f1ff; border: none; border-radius: 0 20px 20px 0px;">Create Checklist</button>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>
<script>
  import { sendData } from '@/helpers/helpers';
  export default{
    name:'CreateChecklistComponent',
    props: {
      assigns: {
        type: Object,
        required: true
      },
    },
    methods: {
      async createChecklistApiCall()
			{
				let output = await sendData(`https://app.kruti.io/api/checklist/create`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						title: this.title,
					})
				});

				if(output.status == 1){
					this.title = null;
					this.$notify.show(output.message, 'success');
				}else{
					this.$notify.show(output.message, 'error');
				}

				console.log(output);
			}
    }
  }
</script>
